import { Component, Input, QueryList, ViewChild, ViewChildren, ElementRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { FormUtil } from "@services/form-util";
import { InputHelper } from "@services/input-helper";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import { CloneShipmentItem } from "./clone-shipment-item";
import { CloneWeeklyOrder } from "./clone-weekly-order";
import { FormDataCloneOrderItem, FormDataCloneShipmentItem, RequestCloneOrder } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { BizError } from "@wearewarp/universal-libs";
import { DlgCloneOrderSuccess } from "./dlg-clone-success";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";

@Component({
  selector: "[clone-shipment]",
  templateUrl: "./index.html",
  styleUrls: ["../../../dialogs/dialogs.scss", "../../../../styles/row-col.scss", "./index.scss"],
})
export class CloneShipment extends BaseFormDialog1 {

  @Input() onSubmitSuccess: (resp) => void = () => {};
  
  protected formGroupDeclaration: FormGroupDeclaration = {
    quantity: { label: "Quantity", type: 'number', initialValue: 1},
    isNotAutoRouteFTL: {label: 'Stop auto routing FTL', type: 'boolean', initialValue: false},
    isCloneLegs: {label: 'Including the legs', type: 'boolean', initialValue: false},
    isCloneRevenue: {label: 'Including Order Revenue only', type: 'boolean', initialValue: false},
    isCloneServiceOptions: {label: 'Including Service Options only', type: 'boolean', initialValue: false},
  };

  @ViewChildren('cloneShipmentItemForms') cloneShipmentItemForms: QueryList<CloneShipmentItem>;
  @ViewChild('cloneWeeklyOrder') cloneWeeklyOrder: CloneWeeklyOrder;

  get isCreateNew(): boolean {
    return !this.model;
  }

  get btnSubmitLabel(): string {
    let orderQuantity = this.orderQuantity;
    if (this.selectTabIndex == 0) orderQuantity = (this.cloneWeeklyOrder?.listDays || []).length;
    return `Clone Order (${orderQuantity})`
  }

  public isError = false;
  public isLoading = false;
  public pickUpNumber = 1;
  public dropOffNumber = 1;
  public shipmentNumber = 1;
  public legNumber = 0;
  public arrModelOrders = [];
  public shipmentType;
  public shipmentEntryMode;
  public isExistsLegs = false;
  public serviceOptions = [];
  public selectTabIndex = 0;

  @Input() orderId;

  constructor() {
    super();
  }

  get warpId() {
    return this.model?.warpId;
  }

  txtCreateMode = '';

  get isSingle() {
    return this.shipmentEntryMode == Const.ShipmentEntryMode.single;
  }

  get isMultiDrop() {
    return this.shipmentEntryMode == Const.ShipmentEntryMode.multiDrop;
  }

  get isMultiPick() {
    return this.shipmentEntryMode == Const.ShipmentEntryMode.multiPick;
  }

  get isMultiPickDrop() {
    return this.shipmentEntryMode == Const.ShipmentEntryMode.multiPickDrop;
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  private getDataDone(err = null) {
    if (err) {
      this.showErr(err);
      this.isError = true;
    } else {
      this.formInput = null;
      this.createFormInput();
    }

    this.isLoading = false;
    if (this.isAdminReadOnlyRole) {
      this.setEnableFormGroup(false);
    }
    this.shipmentType = this.model?.shipmentType || '';
    this.shipmentEntryMode = this.model?.shipmentEntryMode || '';
    this.model.isCloneTime = this.isCloneTime(this.model);
    this.arrModelOrders = [ Utils.cloneObject(this.model) ];
    if (this.shipmentType 
      && this.shipmentType != Const.ShipmentTypes.fullTruckLoad
      && Utils.isArrayNotEmpty(this.model?.metadata?.shipments?.[0]?.legIds)) {
      this.isExistsLegs = true;
    }
  }

  private getData() {
    this.isLoading = true;
    let url = `${Const.APIV2('orders')}/${this.orderId}`;
    this.api.GET(url).subscribe(
      async (resp) => {
        this.model = await this.onGetDetailSuccess(resp.data);
        this.getDataDone();
      },
      (err) => {
        this.getDataDone(err);
      }
    );
  }

  // sau khi lấy deliveryInfos từ API thì tạo data cho pickInfo,dropInfos để bindData vào form
  protected async onGetDetailSuccess(data) {
    this.serviceOptions = data.cost?.serviceOptions || [];
    let serviceOptionsNotIncluded = this.getServiceOptionNotIncluded();
    this.serviceOptions = this.serviceOptions.filter(sv => !serviceOptionsNotIncluded.includes(sv._id))
    let shipments = data.metadata?.shipments || [];
    shipments = this.sortShipmentsFollowSortedTaskIds(shipments, data.sortedTaskIds);
    switch (data.shipmentEntryMode) {
      case Const.ShipmentEntryMode.single: {
        this.txtCreateMode = 'Single Pickup/Single Dropoff';
        this.pickUpNumber = 1;
        this.dropOffNumber = 1;
        const shipment = shipments?.[0] || {};
        const deliveryInfos = shipment?.deliveryInfos || [];
        const pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
        const dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
        let legInfos = [];
        if (Utils.isArrayNotEmpty(shipment.legIds)) {
          const url = `${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipment.id}/childrens`;
          const resp = await this.api.GET(url).toPromise().catch(e => {});
          const childrens = resp?.data?.list_data || [];
          this.legNumber = shipment.legIds.length;
          legInfos = shipment.legIds.map(legId => {
            const leg = childrens.find(it => it.id == legId) || {};
            const deliveryInfos = leg.deliveryInfos || [];
            const pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
            const dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
            const isCloneTime = this.isCloneTime({ pickInfo, dropInfo });
            return {
              isCloneTime,
              originShipmentId: leg.id,
              pickInfo: {...pickInfo, originShipmentId: leg.id},
              dropInfo: {...dropInfo, originShipmentId: leg.id}
            }
          })
        }
        let processed = Object.assign({}, data, {
          legInfos,
          pickInfo: {...pickInfo, originShipmentId: shipment.id}, 
          dropInfo: {...dropInfo, originShipmentId: shipment.id},
        });
        if (Utils.isArrayNotEmpty(shipment.cost?.serviceOptions)) {
          this.serviceOptions = [...shipment.cost?.serviceOptions, ...this.serviceOptions];
          this.serviceOptions = this.serviceOptions.filter(sv => !serviceOptionsNotIncluded.includes(sv._id))
        }
        return processed;
      }
      case Const.ShipmentEntryMode.multiDrop: {
        this.txtCreateMode = 'Single Pickup/Multi Dropoff';
        this.pickUpNumber = 1;
        this.dropOffNumber = shipments.length;
        let pickInfo;
        let dropInfos = [];
        for (let shipment of shipments) {
          const deliveryInfos = shipment?.deliveryInfos || [];
          if (!pickInfo) {
            pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
            pickInfo = { ...pickInfo, originShipmentId: shipment.id };
          }
          const dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
          dropInfos.push({ ...dropInfo, originShipmentId: shipment.id });
        }
        if (Utils.isArrayNotEmpty(data?.sortedTaskIds)) {
          let dropInfosSorted = [];
          for (let i=1; i<data.sortedTaskIds.length; i++) {
            let deliveryId = data.sortedTaskIds[i]?.[0];
            if (deliveryId) {
              let dropInfo = dropInfos.find(item => item.id == deliveryId);
              if (dropInfo) dropInfosSorted.push(dropInfo);
            }
          }
          if (dropInfosSorted.length === dropInfos.length) {
            dropInfos = dropInfosSorted;
          }
        }
        const isCloneTime = this.isCloneTime({ pickInfo, dropInfos });
        let processed = Object.assign({}, data, {pickInfo, dropInfos, isCloneTime});
        return processed;
      }
      case Const.ShipmentEntryMode.multiPick: {
        this.txtCreateMode = 'Multi Pickup/Single Dropoff'
        this.dropOffNumber = 1;
        this.pickUpNumber = shipments.length;
        let pickInfos = [];
        let dropInfo;
        for (let shipment of shipments) {
          const deliveryInfos = shipment?.deliveryInfos || [];
          if (!dropInfo) {
            dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
            dropInfo = { ...dropInfo, originShipmentId: shipment.id };
          }
          const pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
          pickInfos.push({ ...pickInfo, originShipmentId: shipment.id});
        }
        if (Utils.isArrayNotEmpty(data?.sortedTaskIds)) {
          let pickInfosSorted = [];
          for (let i=0; i<data.sortedTaskIds.length-1; i++) {
            let deliveryId = data.sortedTaskIds[i]?.[0];
            if (deliveryId) {
              let pickInfo = pickInfos.find(item => item.id == deliveryId);
              if (pickInfo) pickInfosSorted.push(pickInfo);
            }
          }
          if (pickInfosSorted.length === pickInfos.length) {
            pickInfos = pickInfosSorted;
          }
        }
        const isCloneTime = this.isCloneTime({ pickInfos, dropInfo });
        let processed = Object.assign({}, data, {pickInfos, dropInfo, isCloneTime});
        return processed;
      }
      case Const.ShipmentEntryMode.multiPickDrop: {
        this.txtCreateMode = 'Multi Pickup/Multi Dropoff';
        this.shipmentNumber = shipments.length;
        this.dropOffNumber = 1;
        this.pickUpNumber = 1;
        let shipmentInfos = [];
        for (let shipment of shipments) {
          if (Utils.isArrayNotEmpty(shipment.cost?.serviceOptions)) {
            this.serviceOptions = [...shipment.cost?.serviceOptions, ...this.serviceOptions];
          }
          const deliveryInfos = shipment?.deliveryInfos || [];
          const pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
          const dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
          const isCloneTime = this.isCloneTime({ pickInfo, dropInfo });
          shipmentInfos.push({
            isCloneTime,
            pickInfo: { ...pickInfo, originShipmentId: shipment.id }, 
            dropInfo: { ...dropInfo, originShipmentId: shipment.id }
          });
        }
        this.serviceOptions = this.serviceOptions.filter(sv => !serviceOptionsNotIncluded.includes(sv._id))
        let processed = Object.assign({}, data, {shipmentInfos});
        return processed;
      }
    }
    return data
  }

  private sortShipmentsFollowSortedTaskIds(shipments, sortedTaskIds) {
    if (!Utils.isArrayNotEmpty(shipments) || !Utils.isArrayNotEmpty(sortedTaskIds) || shipments.length == 1) return shipments;
    let arr = [];
    for (let deliveryIds of sortedTaskIds) {
      if (!Utils.isArrayNotEmpty(deliveryIds)) continue;
      if (deliveryIds.length > 1) continue;
      let deliveryId = deliveryIds[0];
      for (let shipment of shipments) {
        const ids = (shipment.deliveryInfos || []).map(item => item.id);
        if (ids.includes(deliveryId)) {
          if (!arr.includes(shipment)) {
            arr.push(shipment)
          }
        }
      }
    }
    // kiểm tra lại tránh bị sót shipment
    for (let shipment of shipments) {
      if (!arr.includes(shipment)) arr.push(shipment)
    }
    return arr;
  }

  protected onCreateSuccess(resp) {
    let orders = resp?.data?.list_data?.map(item => {
      return { id: item.id, warpId: item.warpId, trackingCode: item.trackingCode, code: item?.code }
    });
    if (Utils.isArrayNotEmpty(orders)) {
      this.showDialogCloneComplete(orders);
    } else {
      this.createSuccess(resp);
    }
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  private showDialogCloneComplete(orders) {
    this.modalService.create({
      nzContent: DlgCloneOrderSuccess,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzComponentParams: { 
        orders,
        onSuccess: this.onSubmitSuccess.bind(this)
      }
    });
  }

  get isFTL(): boolean {
    return this.shipmentType == Const.ShipmentTypes.fullTruckLoad
  }

  onBtnSave(): void {
    if (!this.needUpdate) {
      return;
    }
    switch (this.selectTabIndex) {
      case 0: // clone weekly order
        const dataClone = this.cloneWeeklyOrder.prepareDataForCloneOrder();
        if (!dataClone['isCloneRevenue'] && !dataClone['isCloneServiceOption']) {
          const msg = 'Revenue is zero, you can copy revenue by checking <b>Including Order Revenue only</b> option or <b>Including Service Options only</b> option, otherwise you might have to update it later.<br><br>Are you sure you want to continue?';
          return this.confirmYesNo(msg, () => { this.saveDataWeeklyOrder() });
        } else {
          this.saveDataWeeklyOrder();
        }
        break;
      case 1: // clone custom order
        const isCloneRevenue = this.getItemValue('isCloneRevenue');
        const isCloneSO = this.getItemValue('isCloneServiceOptions');
        if (!isCloneRevenue && !isCloneSO) {
          const msg = 'Revenue is zero, you can copy revenue by checking <b>Including Order Revenue only</b> option or <b>Including Service Options only</b> option, otherwise you might have to update it later.<br><br>Are you sure you want to continue?';
          return this.confirmYesNo(msg, () => { this.saveData() });
        } else {
          this.saveData();
        }
        break;
    }
  }

  private async saveData() {
    const { cloneDataInfos } = this.prepareDataForCreateOrders(true);
    const ordersSameRefs = await this.checkExistRefnums(cloneDataInfos);
    if(ordersSameRefs.length) {
      const orderWarpIds = ordersSameRefs.map(it => this.showOrderWarpId(it)).join(', ');
      this.confirmYesNo(`You are creating a new order with the same REF as ${orderWarpIds.length > 1 ? 'orders' : 'order'} ${orderWarpIds}. Do you want to continue to make this order?`, () => {
        this.onSave();
      })
    }
    else this.onSave();
  }

  private async checkExistRefnums(cloneDataInfos) {
    let refNums = [];
    for(let item of cloneDataInfos) {
      const shipments = item?.shipments || [];
      if(!shipments.length) continue;
      for(let shipment of shipments) {
        if(shipment.pickInfo) refNums = [...refNums, ...(shipment.pickInfo?.refNums || [])]
        if(shipment.dropInfo) refNums = [...refNums, ...(shipment.dropInfo?.refNums || [])]
      }
    }
    const clientId = this.model?.clientIds?.[0]
    console.log("clientId", clientId)

    const result = await this.api.POST(Const.APIV2(`${Const.APIURI_ORDERS}/check_exist_refs`), {
      clientId,
      refNums: Utils.uniqElementsArray(refNums.filter(it => it))
    }).toPromise()
    const data = result?.data?.list_data || [];
    return data
  }

  onSave() {
    const { orderData, cloneDataInfos } = this.prepareDataForCreateOrders(true);
    let url = `${Const.APIV2('orders')}/clone_order`;
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.POST(url, { orderData, cloneDataInfos }, { observe: "response" }).subscribe(
      (resp) => {
        let httpCode = resp.status;
        let body = resp.body;
        Log.d(`createData done, statusCode: ${httpCode}, resp: `, body);
        this.onCreateSuccess(body);
        this.stopProgress();
      },
      (err) => {
        Log.e("createData error: ", err);
        if(err?.data?.moduleErrorCode === BizError.ClientCreditLimitExceeded) {
          this.showErrDialog(`The customer credit has exceeded the limit. Please check your customer settings.`);
        } else {
          this.showErr(err);
        }
        this.stopProgress();
        this.setEnableFormGroup(true);
      }
    );
  }

  protected prepareDataForCreateOrders(isCreateNew: boolean): RequestCloneOrder {
    const isNotAutoRouteFTL = this.getItemValue('isNotAutoRouteFTL');
    const isCloneRevenue = this.getItemValue('isCloneRevenue');
    const isCloneServiceOption = this.getItemValue('isCloneServiceOptions');
    const orderCurrentNumber = this.arrModelOrders.length ?? 0;
    let listNewOrder: FormDataCloneOrderItem[] = [];
    for (let i=0;i<orderCurrentNumber;i++) {
      if (this.isSingle) {
        let originShipmentId; 
        let pickInfo;
        let dropInfo;
        for (let key of ['pickInfo']) {
          let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key);
          pickInfo = formCloneShipmentItem.getDataJson();
          originShipmentId = pickInfo?.originShipmentId;
        }
        for (let key of ['dropInfo']) {
          let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key);
          dropInfo = formCloneShipmentItem.getDataJson();
        }
        const newShipment: FormDataCloneShipmentItem = {originShipmentId, pickInfo, dropInfo };
        if (this.isCloneLegs) {
          let legInfos: FormDataCloneShipmentItem[] = [];
          for (let j=0;j<this.legNumber;j++) {
            let originLegId; 
            let legPickInfo;
            let legDropInfo;
            for (let key of ['leg-pickInfo']) {
              let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,0,j);
              legPickInfo = formCloneShipmentItem.getDataJson();
              originLegId = legPickInfo?.originShipmentId;
            }
            for (let key of ['leg-dropInfo']) {
              let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,0,j);
              legDropInfo = formCloneShipmentItem.getDataJson();
            }
            legInfos.push({originShipmentId: originLegId, pickInfo: legPickInfo, dropInfo: legDropInfo})
          }
          newShipment.isCloneLegs = true;
          newShipment.legInfos = legInfos;
        }
        listNewOrder.push({shipments: [newShipment]})
      } else if (this.isMultiDrop) {
        let shipments = [];
        let pickInfo;
        for (let key of ['pickInfo']) {
          let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key);
          pickInfo = formCloneShipmentItem.getDataJson();
        }
        for (let key of ['dropInfos']) {
          for (let j=0;j<this.dropOffNumber;j++) {
            let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,j);
            let dropInfo = formCloneShipmentItem.getDataJson();
            let originShipmentId = dropInfo.originShipmentId;
            shipments.push({originShipmentId, pickInfo, dropInfo});
          }
        }
        listNewOrder.push({shipments});
      } else if (this.isMultiPick) {
        let shipments = [];
        let dropInfo;
        for (let key of ['dropInfo']) {
          let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key);
          dropInfo = formCloneShipmentItem.getDataJson();
        }
        for (let key of ['pickInfos']) {
          for (let j=0;j<this.pickUpNumber;j++) {
            let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,j);
            let pickInfo = formCloneShipmentItem.getDataJson();
            let originShipmentId = pickInfo.originShipmentId;
            shipments.push({originShipmentId, pickInfo, dropInfo});
          }
        }
        listNewOrder.push({shipments});
      } else if (this.isMultiPickDrop) {
        let shipments = [];
        for (let j=0;j<this.shipmentNumber;j++) {
          let shipment = {}
          let originShipmentId;
          for (let key of ['pickInfo', 'dropInfo']) {
            let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,j);
            shipment[key] = formCloneShipmentItem.getDataJson();
            originShipmentId = shipment[key].originShipmentId;
          }
          shipments.push({originShipmentId, ...shipment});
        }
        listNewOrder.push({shipments});
      }
    }

    let orderData = {
      orderId: this.orderId,
      isNotAutoRouteFTL: isNotAutoRouteFTL,
      isCloneRevenue: isCloneRevenue,
      isCloneServiceOptions: isCloneServiceOption
    };
    return { orderData, cloneDataInfos: listNewOrder }
  }


  protected setEnableFormGroup(enabled: boolean): void {
    super.setEnableFormGroup(enabled);
  }

  protected onUpdateSuccess(resp) {
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  public get getQuantityValue() {
    return this.getItemValue('quantity');
  }

  updateArrayOrderInfo() {
    let qty;
    if (this.getItemValue('quantity') >= 1) {
      qty = this.getItemValue('quantity');
    }
    if (!qty ||  qty < 1 || qty > 9) return;
    const orderCurrentNumber = this.arrModelOrders.length ?? 0;
    if (!orderCurrentNumber) return;
    if (orderCurrentNumber  < qty) {
      for (let i=1; i <= qty - orderCurrentNumber; i++) {
        this.arrModelOrders.push(Utils.cloneObject(this.model));
      }
    } else if (orderCurrentNumber  > qty){
      for (let i=orderCurrentNumber; i > qty; i--) {
        this.arrModelOrders.pop();
      }
    }
  }

  onInputChangedQuantity(event, key) {
    let text = event.target.value;
    text = text.replace(/[^0-9]/g, '');
    if (text.length == 0) {
      text = null;
    }
    if (text && text.length > 1) {
      text = text.replace(/^0+/, '');
      if (text.length == 0) {
        text = '0';
      }
    }
    if (text?.length > 1) {
      this.showInfo(`Quantity ${text} too large!`);
      this.formInput.get(key).setValue(text[0], {emitEvent:false});
      return;
    }
    InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key), {isInteger: true});
    this.updateArrayOrderInfo();
    return;
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'quantity':
        return this.onInputChangedQuantity(event, key);
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'quantity':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  getInputType(key: string): string {
    switch (key) {
      case 'quantity':
        return 'number';
      default:
        FormUtil.getItemByKey(key, this.formGroupDeclaration).inputType || "text"
    }
  }

  get needUpdate() {
    if (this.selectTabIndex == 0) {
      let orderQuantity = (this.cloneWeeklyOrder?.listDays || []).length;
      if (orderQuantity) return true;
      return false;
    }
    const shipmentCurrentNumber = this.arrModelOrders.length ?? 0;
    for (let i=0;i<shipmentCurrentNumber;i++) {
      if (this.isSingle) {
        for (let key of ['pickInfo', 'dropInfo']) {
          let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key);
          if (!formCloneShipmentItem) return false;
          let err = formCloneShipmentItem?.formGroupError;
          if (err) return false;
        }
        if (this.isCloneLegs) {
          for (let j=0;j<this.legNumber;j++) {
            for (let key of ['leg-pickInfo', 'leg-dropInfo']) {
              let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,0,j);
              if (!formCloneShipmentItem) return false;
              let err = formCloneShipmentItem?.formGroupError;
              if (err) return false;
            }
          }
        }
      } else if (this.isMultiDrop) {
        for (let key of ['pickInfo']) {
          let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key);
          if (!formCloneShipmentItem) return false;
          let err = formCloneShipmentItem?.formGroupError;
          if (err) return false;
        }
        for (let key of ['dropInfos']) {
          for (let j=0;j<this.dropOffNumber;j++) {
            let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,j);
            if (!formCloneShipmentItem) return false;
            let err = formCloneShipmentItem?.formGroupError;
            if (err) return false;
          }
        }
      } else if (this.isMultiPick) {
        for (let key of ['pickInfos']) {
          for (let j=0;j<this.pickUpNumber;j++) {
            let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,j);
            if (!formCloneShipmentItem) return false;
            let err = formCloneShipmentItem?.formGroupError;
            if (err) return false;
          }
        }
        for (let key of ['dropInfo']) {
          let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key);
          if (!formCloneShipmentItem) return false;
          let err = formCloneShipmentItem?.formGroupError;
          if (err) return false;
        }
      } else if (this.isMultiPickDrop) {
        for (let j=0;j<this.shipmentNumber;j++) {
          for (let key of ['pickInfo', 'dropInfo']) {
            let formCloneShipmentItem = this.getFormCloneShipmentItem(i,key,j);
            if (!formCloneShipmentItem) return false;
            let err = formCloneShipmentItem?.formGroupError;
            if (err) return false;
          }
        }
      }
    }
    return true;
  }

  private getFormCloneShipmentItem(orderIndex: number,key: string, index: number = 0, legIndex: number = 0): CloneShipmentItem {
    if (this.isSingle) {
      let numberLegs = this.isCloneLegs ? this.legNumber : 0;
      let startIndex = orderIndex * (2 + numberLegs * 2);
      switch (key) {
        case 'pickInfo': return this.cloneShipmentItemForms.get(startIndex);
        case 'dropInfo': return this.cloneShipmentItemForms.get(startIndex+1);
        case 'leg-pickInfo': return this.cloneShipmentItemForms.get(startIndex + 2 + legIndex * 2);
        case 'leg-dropInfo': return this.cloneShipmentItemForms.get(startIndex + 2 + legIndex * 2 + 1);
        default: return null;
      }
    } else if (this.isMultiDrop) {
      let startIndex = orderIndex * (1 + this.dropOffNumber);
      switch (key) {
        case 'pickInfo': return this.cloneShipmentItemForms.get(startIndex);
        case 'dropInfos': return this.cloneShipmentItemForms.get(startIndex+1+index);
        default: return null;
      }
    } else if (this.isMultiPick) {
      let startIndex = orderIndex * (1 + this.pickUpNumber);
      switch (key) {
        case 'pickInfos': return this.cloneShipmentItemForms.get(startIndex+index);
        case 'dropInfo': return this.cloneShipmentItemForms.get(startIndex+this.pickUpNumber);
        default: return null;
      }
    } else if (this.isMultiPickDrop) {
      let startIndex = orderIndex * this.shipmentNumber * 2;
      let shipmentIndex = index * 2;
      switch (key) {
        case 'pickInfo': return this.cloneShipmentItemForms.get(startIndex+shipmentIndex);
        case 'dropInfo': return this.cloneShipmentItemForms.get(startIndex+shipmentIndex+1);
        default: return null;
      }
    }
  }

  public getLabelLocation(key, index = 0) {
    if (this.isSingle || this.isMultiPickDrop) {
      return key == 'pickInfo' ? 'Pickup' : 'Delivery';
    } else if (this.isMultiDrop) {
      return key == 'pickInfo' ? 'Pickup' : `Delivery ${index+1}`;
    } else if (this.isMultiPick) {
      return key == 'pickInfos' ? `Pickup ${index+1}` : 'Delivery';
    }
  }

  public get isCloneLegs() {
    return this.getItemValue('isCloneLegs');
  }

  private isCloneTime(data) {
    let item = Utils.cloneObject(data)
    const { pickInfo, dropInfo, pickInfos = [], dropInfos = [] } = item;
    if(pickInfos.length) {
      let statuses = pickInfos.map(item => this.isCloneTime({ pickInfo: item, dropInfo }));
      let statusUniq = Utils.uniqElementsArray(statuses);      
      return statusUniq.includes(false) ? false : true;
    }
    if(dropInfos.length) {
      let statuses = dropInfos.map(item => this.isCloneTime({ pickInfo, dropInfo: item }));
      let statusUniq = Utils.uniqElementsArray(statuses);
      return statusUniq.includes(false) ? false : true;
    }

    let pickWindow = pickInfo?.windows?.[0];
    let dropWindow = dropInfo?.windows?.[0];
    let pickTimezone = pickInfo?.addr?.metadata?.timeZoneStandard;
    let dropTimezone = dropInfo?.addr?.metadata?.timeZoneStandard;

    if(!pickWindow?.from || !dropWindow?.from) return false;

    let now = new Date().getTime();
    let pickTimstamp = new Date(DateUtil.convertLocalTime2(pickWindow?.from, pickTimezone)).getTime();
    let dropTimstamp = new Date(DateUtil.convertLocalTime2(dropWindow?.from, dropTimezone)).getTime();
    if(pickTimstamp > now && dropTimstamp > now) return true;
    return false;
  }

  isCloneServiceOptions() {
    if (this.selectTabIndex == 0) return this.cloneWeeklyOrder?.isCloneServiceOption || false;
    if (this.selectTabIndex == 1) return this.getItemValue('isCloneServiceOptions');
  }

  removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  getServiceOptionName(): string {
    if (!this.serviceOptions || this.serviceOptions.length == 0) return 'N/A';
    let name = []
    this.removeDuplicates(this.serviceOptions).forEach(sv => {
      name.push(MasterData.getServiceOptionName(sv._id))
    })
    return name.join(', ');
  }

  get orderQuantity() {
    return this.getQuantityValue;
  }

  private async saveDataWeeklyOrder() {
    const dataClone = this.cloneWeeklyOrder.prepareDataForCloneOrder().cloneDataInfos;
    const cloneDataInfos = dataClone.map(item => {
      return { shipments: [{ pickInfo: item }] };
    })
    
    const ordersSameRefs = await this.checkExistRefnums(cloneDataInfos);
    if(ordersSameRefs.length) {
      const orderWarpIds = ordersSameRefs.map(it => this.showOrderWarpId(it)).join(', ');
      this.confirmYesNo(`You are creating a new order with the same REF as ${orderWarpIds.length > 1 ? 'orders' : 'order'} ${orderWarpIds}. Do you want to continue to make this order?`, () => {
        this.onSaveWeeklyOrder();
      })
    }
    else this.onSaveWeeklyOrder();
  }

  private onSaveWeeklyOrder() {
    const dataClone = this.cloneWeeklyOrder.prepareDataForCloneOrder();
    let url = `${Const.APIV2('orders')}/clone_weekly_order`;
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.POST(url, { orderId: this.orderId, ...dataClone} , { observe: "response" }).subscribe(
      (resp) => {
        let httpCode = resp.status;
        let body = resp.body;
        Log.d(`createData done, statusCode: ${httpCode}, resp: `, body);
        this.onCreateSuccess(body);
        this.stopProgress();
      },
      (err) => {
        Log.e("createData error: ", err);
        if(err?.data?.moduleErrorCode === BizError.ClientCreditLimitExceeded) {
          this.showErrDialog(`The customer credit has exceeded the limit. Please check your customer settings.`);
        } else {
          this.showErr(err);
        }
        this.stopProgress();
        this.setEnableFormGroup(true);
      }
    );
  }

  onChangeTabIndex(event) {
    this.selectTabIndex = event;
    if(this.selectTabIndex == 1) this.getDataDone();
  }

  getServiceOptionNotIncluded() {
    let serviceOptionsNotIncluded: string[] = ['tonu', 'storage', 'reconsignment-fee', 'lumper', 'detention-receiver', 'detention-shipper', 
      'pickup-layover', 'delivery-layover', 'extra-stop', 'extra-miles'];
    return serviceOptionsNotIncluded;
  }

  getServiceOptionNotIncludedName(): string {
    let name = []
    this.getServiceOptionNotIncluded().forEach(sv => {
      name.push(MasterData.getServiceOptionName(sv))
    })
    return name.join(', ');
  }
}
