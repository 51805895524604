import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { BaseFormItem } from "@app/admin/base/form-item";
import { FormUtil } from "@services/form-util";
import { ExtendValidators } from "@app/admin/base/validator";
import { FormDataWindowTime, WindowTimeHelper } from "@app/admin/shipment-entry/components/forms/shipment-location/helper/window";
import { FormDataCloneDeliveryItem } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { Contact } from "@wearewarp/types/data-model";
import { Const } from "@const/Const";
import _ from "underscore";
import { Utils } from "@services/utils";
import { MasterData } from "@services/master.data";

@Component({
  selector: "[clone-shipment-item]",
  templateUrl: "./index.html",
  styleUrls: ["../../../../dialogs/dialogs.scss", "../../../../../styles/row-col.scss", "./index.scss"],
})
export class CloneShipmentItem extends BaseFormItem {

  public static get declaration(): FormGroupDeclaration {
    return {
      originShipmentId: { label: '' },
      type: { label: '' },
      requiresAppointment: { label: 'Location requires appointment', type: 'boolean', initialValue: false },
      refNums: {
        label: 'Reference Number', placeHolder: 'Enter Ref#', type: 'formArray', childItem: {
          label: 'Ref', notAcceptEmpty: true
        }, initialValue: ['']
      },
      windows: {
        label: '', type: 'formArray', required: true, initialValue: [{}], childItem: {
          range: { label: '', required: true, validators: ExtendValidators.validateTimeWindow }    // dùng với <nz-range-picker>
        }
      }
    }
  }

  protected formGroupDeclaration: FormGroupDeclaration = CloneShipmentItem.declaration;

  private keys = [];
  get formInputKeys(): Array<string> {
    return this.keys;
  }

  protected getApiUrl(): string {
    return null;
  }

  @Input() labelLocation;
  @Input() isReturnToDepot = false;
  @Input() isCloneTime: boolean = false;
  @Input() isCloneServiceOptions: boolean = false;
  @Input() isCloneLegs: boolean = false;

  public addressInfo = '';
  public locationDetail: any = {};
  public shipmentContacts: Contact[] = [];
  public locationContacts: Contact[] = [];
  public isSameContacts: boolean = true;
  private serviceOptions: string[] = [];

  @Input() set bindData(model: any) {
    this.model = model;
    let timezone = model.addr?.metadata?.timeZoneStandard;
    this.model.windows = this.isCloneTime ? WindowTimeHelper.modelToFormData(this.model.windows, timezone) : [{}];
    if (!this.isReturnToDepot) {
      this.model.refNums = null;
    }
    this.shipmentContacts = Utils.cloneObject(this.model?.contacts || []);
    this.getLocationData();
    this.addressInfo = this.getAddressText(this.model?.addr);
    this.serviceOptions = this.model?.serviceOptions || [];
    if (this.formInput) {
      this.bindDataModel(this.model);
      this.setEnableFormGroup(true);
    }
  }

  private getLocationData() {
    let warehouseId = this.model?.warehouseId || "";
    if (!warehouseId) return;

    this.api.GET(`${Const.APIURI_LOCATION}/${warehouseId}`).subscribe(
      resp => {
        this.locationDetail = resp?.data || {};
        this.locationContacts = Utils.cloneObject(this.locationDetail?.contacts || []);
        this.checkSameContacts();
      }, err => {
        this.showErr(err);
      }
    );
  }

  private checkSameContacts() {
    let activeShipmentContacts = this.sortContacts(this.getActiveContacts(this.shipmentContacts));
    let activeLocationContacts = this.sortContacts(this.getActiveContacts(this.locationContacts));
    this.isSameContacts = JSON.stringify(activeShipmentContacts) == JSON.stringify(activeLocationContacts)
  }

  private sortContacts(contacts: Contact[]) {
    if (!contacts?.length) return [];

    return _(contacts).chain()
      .sortBy(function (patient) {
        return patient.fullName;
      })
      .sortBy(function (patient) {
        return patient.phone;
      })
      .sortBy(function (patient) {
        return patient.email;
      })
      .value();
  }

  private getActiveContacts(contacts: Contact[]) {
    if (!contacts?.length) return [];

    let arr: any = [];
    contacts.map(item => {
      let check = !item?.fullName && !item?.phone && !item?.email;
      if (!check) arr.push({
        fullName: item?.fullName || "",
        phone: item?.phone || "",
        email: item?.email || "",
      })
    });
    return arr
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  public isError = false;
  public isLoading = false;

  constructor() {
    super();
  }

  get formGroupError() {
    let err = super.formGroupError;
    return err;
  }

  protected getFormData_JSON(isCreateNew: boolean): FormDataCloneDeliveryItem {
    // Convert các trường {date: Date, fromTime: Date, toTime: Date} thành time window dạng {from: ISOString, to: ISOString}
    let data = super.getFormData_JSON(true);
    let metadata = this.model.addr.metadata;
    let timezone = metadata?.timeZoneStandard;
    let windows = WindowTimeHelper.formDataToModel(<FormDataWindowTime[]>(data.windows ?? []), timezone);
    data.windows = windows;
    return data;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addressInfo = this.getAddressText(this.model?.addr);
    this.onRequiresAppointmentChange();
  }

  isRequireAppointment(): boolean {
    return this.getItemValue(`requiresAppointment`);
  }

  onRequiresAppointmentChange() {
    let isAppointment = this.isRequireAppointment();
    if (this.isCloneLegs) {
      return this.updateRequireForDateTime(false);
    }
    this.updateRequireForDateTime(!isAppointment)
  }

  private updateRequireForDateTime(isRequired: boolean) {
    this.formGroupDeclaration['windows'].required = isRequired;
    let childKeys = ['range'];
    for (let childKey of childKeys) {
      this.formGroupDeclaration['windows'].childItem[childKey].required = isRequired;
    }
    if (!this.formInput) {
      return;
    }
    let fa = this.getFormArrayTimeWindows();
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      for (let childKey of childKeys) {
        let fc = <FormControl>fg.get(childKey);
        if (fc) {
          // Nếu requires appointment thì các trường requested date/time của [pickInfo/dropInfo] ko bắt buộc nữa
          if (!isRequired) {
            fc.removeValidators(Validators.required);
            fc.removeValidators(ExtendValidators.validateTimeWindow);
          } else {
            fc.addValidators(Validators.required);
            fc.addValidators(ExtendValidators.validateTimeWindow);
          }
          fc.updateValueAndValidity();
        }
      }
    }
  }

  protected setEnableFormGroup(enabled: boolean): void {
    super.setEnableFormGroup(enabled);
  }

  onInputChanged(event, key) {
    switch (key) {
            default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  getInputType(key: string): string {
    switch (key) {
      default:
        return FormUtil.getItemByKey(key, this.formGroupDeclaration).inputType || "text"
    }
  }

  getControlTimeWindows() {
    return this.getFormArrayTimeWindows()?.controls ?? [];
  }

  private getFormArrayTimeWindows(): FormArray {
    return <FormArray>this.formInput.get('windows');
  }

  get countRefNums() {
    return this.getFormArrayLength('refNums');
  }

  onBtnAddFormArray(key) {
    this.addItemToFormArray(key);
  }

  onBtnRemoveFormArray(key, index: number) {
    let itemValue = this.getItemValue(`${key}[${index}]`);
    let message = itemValue ? `Remove reference number <b>${itemValue}</b>?` : `Remove reference number at position <b>${index + 1}</b>?`;
    this.confirmDeletion({
      message: message,
      txtBtnOk: 'Remove',
      fnOk: () => this.removeItemInFormArray(key, index)
    });
  }

  get contacts() {
    return this.model?.contacts || []
  }

  getDataJson() {
    let data = this.formData_JSON(true);
    data.contacts = this.contacts;
    return data
  }

  getServiceOptionName(): string {
    if (!this.serviceOptions || this.serviceOptions.length == 0) return 'N/A';
    let name = [];
    let serviceOptionsNotIncluded: string[] = ['tonu', 'storage', 'reconsignment-fee', 'lumper', 'detention-receiver', 'detention-shipper', 
      'pickup-layover', 'delivery-layover', 'extra-stop', 'extra-miles'];
    this.serviceOptions.forEach(sv => {
      if (!serviceOptionsNotIncluded.includes(sv)) name.push(MasterData.getServiceOptionName(sv))
    })
    return name.join(', ') || "N/A";
  }

}
