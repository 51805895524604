import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: '[clone-popup-delivery-info]',
    templateUrl: './index.html',
    styleUrls: ['./styles.scss']
})
export class ClonePopupDeliveryInfo {
    @Input() locationType: string;
    @Input() deliveryDetail: any;

    constructor() {}
    public daysOfWeek: string[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ];
    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['deliveryDetail']) {
            // console.log('ngOnChanges - deliveryDetail:', this.deliveryDetail);
        }
    }
}
