<nz-collapse class="locationCollapse">
  <nz-collapse-panel [nzHeader]="collapseHeader" [nzShowArrow]="false" [nzActive]="true" [nzDisabled]="disabled">
    <ng-template #collapseHeader>
      <div class="mainTitleCollapse" style="width: 100%;">
        <div class="flex-space-between title-header center-vertical">
          <span class="right10">{{titleHeader}}</span>
          <span
              *ngIf="selected"
              nz-icon
              [nzType]="'check-circle'"
              [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#52c41a'"
              style="font-size: 20px"
            ></span>
        </div>
      </div>
    </ng-template>

    <div class="info">
      <div>
        <div class="label-info primaryContact">Primary Contact: {{primaryContact?.fullName || 'N/A' }}</div>
        <div class="label-info">Phone Number: {{primaryContact?.phone || 'N/A' }}</div>
        <div class="label-info">Email: {{primaryContact?.email || 'N/A' }}</div>
      </div>

      <div class="separator h bottom20 top20"></div>

      <div>
        <div class="label-info secondaryContact">Secondary Contact: {{secondaryContact?.fullName || 'N/A' }}</div>
        <div class="label-info">Phone Number: {{secondaryContact?.phone || 'N/A' }}</div>
        <div class="label-info">Email: {{secondaryContact?.email || 'N/A' }}</div>
      </div>
    </div>
  </nz-collapse-panel>
</nz-collapse>
