import { Component, Input } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Utils } from "@services/utils";
import { ContactType } from "@wearewarp/types";
import { Contact } from "@wearewarp/types/data-model";
import { Const } from "@wearewarp/universal-libs";

@Component({
  selector: "[display-delivery-contact]",
  templateUrl: "./index.html",
  styleUrls: ["../../../../dialogs/dialogs.scss", "../../../../../styles/row-col.scss", "./index.scss"],
})
export class DisplayDeliveryContact extends BaseFormItem {

  private initContact(type: ContactType) {
    return {
      fullName: "",
      phone: "",
      phoneExtension: "",
      email: "",
      type: type
    }
  }

  @Input() titleHeader: string;
  @Input() disabled: boolean = false;
  @Input() selected: boolean = false;

  private contacts: Contact[] = [];
  public primaryContact: Contact;
  public secondaryContact: Contact;
  @Input() set bindData(contacts: Contact[]) {
    this.contacts = contacts;
    this.primaryContact = this.getContact(Const.ContactType.primary);
    this.secondaryContact = this.getContact(Const.ContactType.secondary);
  }

  private getContact(type: ContactType) {
    let lists: any[] = Utils.cloneObject(this.contacts || []);
    return lists.find(item => item.type == type) || this.initContact(type);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
