<div nz-row [nzGutter]="{ sm: 16 }" class="top10">
    <div nz-col nzSm="6" class="flex label-date">{{getLabelDay()}}</div>
    <div nz-col nzSm="6"><nz-date-picker nzDisabled style="width: 100%;" [ngModel]="pickupDate"></nz-date-picker></div>
    <div nz-col nzSm="12">
        <form [formGroup]="formInput" nz-form>
            <div *ngFor="let childKey of ['refNums']">
                <ng-container [formArrayName]="childKey">
                    <div *ngFor="let f of getArrayControls(childKey); let arrIndex = index" style="display: flex;">
                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input [formControlName]="arrIndex"  style="width: 200px;"
                                    [type]="getInputType(fullKey(childKey))" 
                                    [placeholder]="getPlaceHolder(fullKey(childKey))"
                                    (input)="onInputChanged($event, childKey)" 
                                    (keypress)="onInputKeyPress($event, childKey)">
                            </nz-form-control>
                        </nz-form-item>
                        <div class="left20" style="padding-top: 5px;">
                            <a *ngIf="countRefNums > 1" (click)="onBtnRemoveFormArray(childKey, arrIndex)" class="danger right20">
                                <i nz-icon nzType="close-square" nzTheme="outline" style="font-size: 20px;"></i>
                            </a>
                        </div>
                        <button *ngIf="arrIndex == countRefNums - 1" nz-button nzType="dashed" (click)="onBtnAddFormArray(childKey)">
                            <span nz-icon nzType="plus" nzTheme="outline"></span> Add more reference number
                        </button>
                    </div>
                </ng-container>
            </div>
        </form>
    </div>
</div>