<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div *ngIf="!isLoading">
  <div class="flex-space-between" style="padding: 20px 20px 0;">
    <div class="f16m bottom20 flex1">Clone Order {{showOrderCode(model)}} 
      <span *ngIf="txtCreateMode" class="f16m left10">({{txtCreateMode}})</span>
    </div>
    <span nz-icon nzType="close" nzTheme="outline" (click)="closeDialog()"></span>
  </div>
  <div class="separator h bottom10"></div>
</div>
<form *ngIf="!isLoading" class="form-detail" [formGroup]="formInput" nz-form>
  <div class="shipment-selection">
    <div style=" width: 100%;height: 100%;display: flex;">
      <nz-tabset [nzSelectedIndex]="selectTabIndex" (nzSelectedIndexChange)="onChangeTabIndex($event)">
        <nz-tab nzTitle="Clone weekly orders"></nz-tab>
        <nz-tab nzTitle="Custom clone order"></nz-tab>
      </nz-tabset>
    </div>
    <div *ngIf="selectTabIndex == 0" clone-weekly-order #cloneWeeklyOrder [bindData]="model.pickInfo">
    </div>
    <div *ngIf="selectTabIndex == 1">
      <div *ngIf="isFTL" class="bottom20">
        <div *ngFor="let key of ['isNotAutoRouteFTL']" class="flex">
          <div class="form-label-v2 fix-height single-line">{{getLabel(key)}}</div>
          <div nz-checkbox [formControlName]="key" style="margin-left: 5px;"></div>
        </div>
      </div>
      <div class="top5 flex">
        <div *ngFor="let key of ['quantity']" class="flex right40">
          <div class="form-label-v2">{{getLabel(key)}}</div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input [formControlName]="key" class="quantity"
                [type]="getInputType(key)"
                min="1" max="9"
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)" 
                (keypress)="onInputKeyPress($event, key)" >
            </nz-form-control>
          </nz-form-item>
        </div>
        <div *ngIf="isExistsLegs" class="bottom20">
          <div *ngFor="let key of ['isCloneLegs']" class="flex">
            <div class="form-label-v2 fix-height single-line">{{getLabel(key)}}</div>
            <div nz-checkbox [formControlName]="key" style="margin-left: 5px;"></div>
          </div>
        </div>
        <div style="margin-left: 30px;">
          <div *ngFor="let key of ['isCloneRevenue']" class="flex">
            <div class="form-label-v2 fix-height single-line">{{getLabel(key)}}</div>
            <div nz-checkbox [formControlName]="key" style="margin-left: 5px;"></div>
          </div>
        </div>
        <div style="margin-left: 30px;">
          <div *ngFor="let key of ['isCloneServiceOptions']" class="flex">
            <div class="form-label-v2 fix-height single-line">{{getLabel(key)}}</div>
            <div nz-checkbox [formControlName]="key" style="margin-left: 5px;"></div>
          </div>
        </div>
      </div>
      
      <div class="section-shipment-details">
        <form *ngFor="let modelOrder of arrModelOrders; let shipmentIndex = index">
          <div class="separator h bottom20"></div>
          <div nz-row [nzGutter]="{ sm: 16 }" class="top10 bottom10">
            <div nz-col nzSm="1" class="bold">{{shipmentIndex+1}}</div>
            <div nz-col nzSm="23">
              <div *ngIf="isSingle">
                <div *ngFor="let key of ['pickInfo']">
                  <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="modelOrder.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [bindData]="modelOrder.pickInfo"  [labelLocation]="getLabelLocation(key)"></div>
                </div>
                <div class="separator h bottom20 top20"></div>
                <div *ngFor="let key of ['dropInfo']">
                  <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="modelOrder.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [bindData]="modelOrder.dropInfo" [labelLocation]="getLabelLocation(key)"></div>
                </div>
                <ng-container *ngIf="isCloneLegs && modelOrder.legInfos?.length">
                  <div class="separator h bottom20"></div>
                  <div *ngFor="let item of modelOrder.legInfos; let legIndex = index" class="top5 flex">
                    <div class="bold right10" style="min-width:100px">
                      Leg {{legIndex+1}}
                    </div>
                    <div>
                      <div *ngFor="let subKey of ['pickInfo']">
                        <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="item.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [isCloneLegs]="isCloneLegs" [bindData]="item.pickInfo" [labelLocation]="getLabelLocation(subKey)"></div>
                      </div>
                      <div class="separator h bottom20 top20"></div>
                      <div *ngFor="let subKey of ['dropInfo']">
                        <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="item.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [isCloneLegs]="isCloneLegs" [bindData]="item.dropInfo" [labelLocation]="getLabelLocation(subKey)"></div>
                      </div>
                      <div *ngIf="legIndex != modelOrder.legInfos.length-1" class="separator h bottom20"></div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="isMultiDrop">
                <div *ngFor="let key of ['pickInfo']">
                  <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="modelOrder.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [bindData]="modelOrder.pickInfo" [labelLocation]="getLabelLocation(key)"></div>
                </div>
                <div *ngFor="let key of ['dropInfos']">
                  <div *ngFor="let item of modelOrder.dropInfos; let dropIndex = index" class="top5">
                    <div class="separator h bottom20 top20"></div>
                    <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="modelOrder.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [bindData]="modelOrder.dropInfos[dropIndex]" [labelLocation]="getLabelLocation(key,dropIndex)"></div>
                  </div>
                </div>
              </div>
              <div *ngIf="isMultiPick">
                <div *ngFor="let key of ['pickInfos']">
                  <div *ngFor="let item of modelOrder.pickInfos; let pickIndex = index" class="top5">
                    <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="modelOrder.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [bindData]="modelOrder.pickInfos[pickIndex]" [labelLocation]="getLabelLocation(key,pickIndex)"></div>
                    <div class="separator h bottom20 top20"></div>
                  </div>
                </div>
                <div *ngFor="let key of ['dropInfo']">
                  <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="modelOrder.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [bindData]="modelOrder.dropInfo" [labelLocation]="getLabelLocation(key)"></div>
                </div>
              </div>
              <div *ngIf="isMultiPickDrop">
                <div *ngFor="let key of ['shipmentInfos']">
                  <div *ngFor="let item of modelOrder.shipmentInfos; let shipmentIndex = index" class="top5 flex">
                    <div class="bold right10" style="min-width:100px">
                      Shipment {{shipmentIndex+1}}
                    </div>
                    <div>
                      <div *ngFor="let subKey of ['pickInfo']">
                        <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="item.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [bindData]="item.pickInfo" [labelLocation]="getLabelLocation(subKey)"></div>
                      </div>
                      <div class="separator h bottom20 top20"></div>
                      <div *ngFor="let subKey of ['dropInfo']">
                        <div clone-shipment-item #cloneShipmentItemForms [isCloneTime]="item.isCloneTime" [isCloneServiceOptions]="isCloneServiceOptions()" [bindData]="item.dropInfo" [labelLocation]="getLabelLocation(subKey)"></div>
                      </div>
                      <div *ngIf="shipmentIndex != modelOrder.shipmentInfos.length-1" class="separator h bottom20"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div *ngIf="isCloneServiceOptions()" class="text-service-options" 
    style="border-top: 1px solid #d9d9d9; padding-top: 20px;">
    <b>Revenue Service Options:</b> {{getServiceOptionName()}}
    <div><b>Service Options not included:</b> {{getServiceOptionNotIncludedName()}}</div>
  </div>
  
</form>

<div form-footer [onProgress]="onProgress" 
  [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && !isLoading"
  [canClickCancel]="!onProgress" [labelOK]="btnSubmitLabel"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>