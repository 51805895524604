<div *ngIf="deliveryDetail" nz-row class="popup-location-detail">
    <div class="location-detail" nz-col nzSpan="12">
        <span class="title">
            {{ locationType === 'DROPOFF' ? 'Delivery Details:' : 'Pickup Details:' }}
        </span>
        <div class="instruction">
            <span class="title-sub">
                Instructions:
            </span>
            <div *ngIf="locationType === 'DROPOFF'">
                {{ deliveryDetail?.dropDetails?.instructions || 'N/A' }}
            </div>
            <div *ngIf="locationType === 'PICKUP'">
                {{ deliveryDetail?.pickDetails?.instructions || 'N/A' }}
            </div>
        </div>
        <div class="note">
            <span class="title-sub">
                Notes:
            </span>
            <div *ngIf="locationType === 'DROPOFF'">
                {{ deliveryDetail?.dropDetails?.note || 'N/A' }}
            </div>
            <div *ngIf="locationType === 'PICKUP'">
                {{ deliveryDetail?.pickDetails?.note || 'N/A' }}
            </div>
        </div>
    </div>
    <div class="operating" nz-col nzSpan="12">
        <span class="title" style="white-space: nowrap;">
            Operating Hours :
        </span>
        <div *ngFor="let day of daysOfWeek; let i = index" class="dflex" style="justify-content: space-between; width: 100%; margin-bottom: 3px;">
            {{ day }}:
            <span *ngIf="deliveryDetail?.operatingHours?.[i]?.isOpen">
                {{ deliveryDetail?.operatingHours?.[i]?.fromTime }} - {{ deliveryDetail?.operatingHours?.[i]?.toTime }}
            </span>
            <span *ngIf="!deliveryDetail?.operatingHours?.[i]?.isOpen || !deliveryDetail?.operatingHours?.[i]">Closed</span>
        </div>
    </div>
</div>
