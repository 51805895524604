import { Component, Input } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DeliveryInfo } from "@wearewarp/types/data-model";
import { DateUtil } from "@services/date-utils";

@Component({
selector: "[clone-weekly-order-item]",
templateUrl: "./index.html",
styleUrls: ["../../../../../dialogs/dialogs.scss", "../../../../../../styles/row-col.scss", "./index.scss"],
})
export class CloneWeeklyOrderItem extends BaseFormItem {

    public static get declaration(): FormGroupDeclaration {
        return {
            refNums: {
                label: 'Reference Number', placeHolder: 'Enter Ref#', type: 'formArray', childItem: {
                    label: 'Ref', notAcceptEmpty: true
                }, initialValue: ['']
            },
        }
      }
    
    protected formGroupDeclaration: FormGroupDeclaration = CloneWeeklyOrderItem.declaration;

    @Input() dayOfWeek = 0;
    @Input() pickInfo: DeliveryInfo;

    daysOfWeek = [
        { value: 0, label: "SUN" },
        { value: 1, label: "MON" },
        { value: 2, label: "TUE" },
        { value: 3, label: "WED" },
        { value: 4, label: "THU" },
        { value: 5, label: "FRI" },
        { value: 6, label: "SAT" },
    ]

    public pickupDate: Date = new Date();
    public listRefNums: string[] = [''];

    ngOnInit() {
        super.ngOnInit();
        this.pickupDate = DateUtil.getDateOfNextWeek(this.dayOfWeek);
    }

    getLabelDay() {
        let item = this.daysOfWeek.find(day => day.value == this.dayOfWeek);
        return item.label;
    }

    getDateInfo() {
        let { windows, appointmentInfo, addr } = this.pickInfo;
        const pickupTimeZone = addr?.metadata?.timeZoneStandard;
        let fromTime: any;
        if (windows && windows[0]) fromTime = windows[0].from;
        if (appointmentInfo && appointmentInfo?.from) fromTime = appointmentInfo.from;
        fromTime = DateUtil.convertLocalTime2(fromTime, pickupTimeZone);
        return fromTime;
    }
    
    getShiftDays() {
        let dateInfo = this.getDateInfo();
        dateInfo = DateUtil.toBeginOfDay(dateInfo);
        const diffInMilliseconds = this.pickupDate.getTime() - dateInfo.getTime();
        const diffInDays = diffInMilliseconds / (1000 * 3600 * 24);
        return Math.round(diffInDays);
    }

    onInputChanged(event, key) {
        switch (key) {
            default:
                return super.onInputChanged(event, key);
        }
      }
    
      onInputKeyPress(event, key) {
        switch (key) {
            default:
                return super.onInputKeyPress(event, key);
        }
    }

    get countRefNums() {
        return this.getFormArrayLength('refNums');
    }
    
    onBtnAddFormArray(key) {
        this.addItemToFormArray(key);
    }
    
    onBtnRemoveFormArray(key, index: number) {
        let itemValue = this.getItemValue(`${key}[${index}]`);
        let message = itemValue ? `Remove reference number <b>${itemValue}</b>?` : `Remove reference number at position <b>${index + 1}</b>?`;
        this.confirmDeletion({
            message: message,
            txtBtnOk: 'Remove',
            fnOk: () => this.removeItemInFormArray(key, index)
        });
    }

    getDataClone() {
        const { refNums } = this.getFormData_JSON(true);
        let refs = [];
        refs = refNums.filter(ref => ref != null && ref != '');
        return { shiftDays: this.getShiftDays(), refNums: refs };
    }
}
