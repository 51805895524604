<form *ngIf="!isLoading" [formGroup]="formInput" nz-form>
  <div class="shipment-selection">
      <div class="section-shipment-details">
        <div nz-row [nzGutter]="{ sm: 16 }">
          <div nz-col nzSm="24">
            <div nz-row [nzGutter]="{ sm: 16 }">
              <div nz-col [nzSm]="isReturnToDepot ? 9 : 4" class="location-info">
                <div class="bold">{{labelLocation}}</div>
                <div class="address-info">
                  {{addressInfo}} <span nz-icon nzType="info-circle" nzTheme="outline" style="margin-left: 3px;" ></span>
                  <div *ngIf="locationDetail" clone-popup-delivery-info [deliveryDetail]="locationDetail" [locationType]="model.type" class="popup-delivery-info"></div>              
                </div>
              </div>
              <div nz-col nzSm="12">
                <div class="flex">
                  <div>
                    <nz-form-item>
                      <nz-form-control>
                        <div *ngFor="let key of ['requiresAppointment']"
                          nz-checkbox [formControlName]="key" 
                          (ngModelChange)="onRequiresAppointmentChange()">
                          {{getLabel(fullKey(key))}}
                        </div>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <div>
                  <div [formArrayName]="'windows'">
                    <form *ngFor="let item of getControlTimeWindows(); let arrIndex = index" nz-form [formGroupName]="arrIndex">
                      <div class="flex">
                        <div class="date-label">Windows
                          <span *ngIf="isRequired('windows')" class="label-mark-required"></span>
                        </div>
                        <div *ngFor="let childKey of ['range']">
                          <nz-form-item class="date-form">
                            <nz-form-control>
                              <nz-range-picker style="margin-right: 20px;"
                                [formControlName]="childKey"
                                [nzPlaceHolder]="getPlaceHolder(fullKey('windows', childKey))"
                                [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15 }"
                                nzFormat="MM-dd-yyyy HH:mm">
                              </nz-range-picker>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!isReturnToDepot">
                <div nz-col nzSm="8" class="flex">
                  <div class="refnum-label">Ref#</div>
                  <div *ngFor="let childKey of ['refNums']">
                    <ng-container [formArrayName]="childKey">
                      <div *ngFor="let f of getArrayControls(childKey); let arrIndex = index" style="display: flex;">
                        <nz-form-item>
                          <nz-form-control>
                            <input nz-input [formControlName]="arrIndex"  style="margin-right: 20px"
                            [type]="getInputType(fullKey(childKey))" 
                            [placeholder]="getPlaceHolder(fullKey(childKey))"
                            (input)="onInputChanged($event, childKey)" 
                            (keypress)="onInputKeyPress($event, childKey)">
                          </nz-form-control>
                        </nz-form-item>
                        <div class="left10" style="padding-top: 5px;">
                          <a *ngIf="countRefNums > 1" (click)="onBtnRemoveFormArray(childKey, arrIndex)" class="danger right10">
                            <i nz-icon nzType="close-square" nzTheme="outline" style="font-size: 20px;"></i>
                          </a>
                        </div>
                      </div>
                    </ng-container>
                    <button nz-button nzType="dashed" (click)="onBtnAddFormArray(childKey)">
                      <span nz-icon nzType="plus" nzTheme="outline"></span> Add more reference number
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="isCloneServiceOptions">
          <b>Service Options:</b> {{getServiceOptionName()}}
        </div>
        <ng-container *ngIf="!isSameContacts">
          <div clone-delivery-contact #cloneDeliveryContact [bindData]="model" [locationData]="locationDetail"></div>
        </ng-container>
      </div>
  </div>
</form>