import { Component, Input } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { FormUtil } from "@services/form-util";
import { Utils } from "@services/utils";
import { ContactType } from "@wearewarp/types";
import { Contact } from "@wearewarp/types/data-model";
import { Const } from "@wearewarp/universal-libs";

@Component({
  selector: "[clone-delivery-contact]",
  templateUrl: "./index.html",
  styleUrls: ["../../../../dialogs/dialogs.scss", "../../../../../styles/row-col.scss", "./index.scss"],
})
export class CloneDeliveryContact extends BaseFormItem {
  public static get declaration(): FormGroupDeclaration {
    return {

    }
  }

  protected formGroupDeclaration: FormGroupDeclaration = CloneDeliveryContact.declaration;

  private keys = [];
  get formInputKeys(): Array<string> {
    return this.keys;
  }

  protected getApiUrl(): string {
    return null;
  }

  private initContact(type: ContactType) {
    return {
      fullName: "",
      phone: "",
      phoneExtension: "",
      email: "",
      type: type
    }
  }

  public contacts: Contact[] = [
    this.initContact(Const.ContactType.primary),
    this.initContact(Const.ContactType.secondary)
  ]; // contacts create new
  public shipmentContacts: Contact[] = [];
  public locationContacts: Contact[] = [];

  public contactSources: any = {
    shipment: {
      value: "shipment",
      label: "From Shipment's Delivery contacts",
    },
    location: {
      value: "location",
      label: "From Location's contacts"
    },
    // other: {
    //   value: "other",
    //   label: "Other..."
    // }
  }
  public contactSelected: string = this.contactSources.shipment.value;
  public get contactSourceOpts() {
    return Object.values(this.contactSources)
  };
  private locationDetail: any = {};

  private getContact(contacts: Contact[], type: ContactType) {
    let lists: any[] = Utils.cloneObject(contacts || []);
    return lists.find(item => item.type == type) || this.initContact(type);
  }

  private getPrimaryContact(contacts: Contact[]) {
    return this.showContact(this.getContact(contacts, Const.ContactType.primary))
  }

  private getSecondaryContact(contacts: Contact[]) {
    return this.showContact(this.getContact(contacts, Const.ContactType.secondary))
  }

  private showContact(contact: Contact) {
    let arr = [];
    if (contact?.fullName) arr.push(contact?.fullName);
    if (contact?.phone) arr.push(contact?.phone);
    if (contact?.email) arr.push(contact?.email);
    return arr.join(" / ") || "N/A"
  }

  private setShipmentContactSource(data: any) {
    this.shipmentContacts = Utils.cloneObject(data?.contacts || []);
    this.contactSources.shipment = {
      ...this.contactSources.shipment,
      primary: this.getPrimaryContact(this.shipmentContacts),
      secondary: this.getSecondaryContact(this.shipmentContacts)
    }
  }

  private setLocationContactSource(data: any) {
    this.locationContacts = Utils.cloneObject(data?.contacts || []);
    this.contactSources.location = {
      ...this.contactSources.location,
      primary: this.getPrimaryContact(this.locationContacts),
      secondary: this.getSecondaryContact(this.locationContacts)
    }
  }

  @Input() set bindData(model: any) {
    this.model = model;
    this.setShipmentContactSource(model);

    if (this.formInput) {
      this.bindDataModel(this.model);
      this.setEnableFormGroup(true);
    }
  }

  @Input() set locationData(data: any) {
    this.locationDetail = data;
    this.setLocationContactSource(data);
  }

  selectContactSource(selected: any) {
    if (!selected || selected == this.contactSources.shipment.value) {
      this.model.contacts = this.shipmentContacts;
    } else if (selected == this.contactSources.location.value) {
      this.model.contacts = this.locationContacts;
    } else {
      this.model.contacts = this.contacts;
    }
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  public isError = false;
  public isLoading = false;

  constructor() {
    super();
  }

  get formGroupError() {
    let err = super.formGroupError;
    return err;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected setEnableFormGroup(enabled: boolean): void {
    super.setEnableFormGroup(enabled);
  }

  onInputChanged(event, key) {
    switch (key) {
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  getInputType(key: string): string {
    switch (key) {
      default:
        return FormUtil.getItemByKey(key, this.formGroupDeclaration).inputType || "text"
    }
  }

  get showInputContacts() {
    return this.contactSelected == this.contactSources?.other?.value
  }

  get hasSelectedShipmentContact() {
    return this.contactSelected == this.contactSources?.shipment?.value
  }

  get hasSelectedLoactionContact() {
    return this.contactSelected == this.contactSources?.location?.value
  }

}
