import { Component, Input, QueryList, ViewChildren } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { CloneWeeklyOrderItem } from "./clone-weekly-order-item";
import { DeliveryInfo } from "@wearewarp/types/data-model";
import { DateUtil } from "@services/date-utils";

@Component({
    selector: "[clone-weekly-order]",
    templateUrl: "./index.html",
    styleUrls: ["../../../../dialogs/dialogs.scss", "../../../../../styles/row-col.scss", "./index.scss"],
})

export class CloneWeeklyOrder extends BaseFormItem {

    @ViewChildren('cloneWeeklyOrderItemForms') cloneWeeklyOrderItemForms: QueryList<CloneWeeklyOrderItem>;

    @Input() set bindData(model: any) {
        this.pickInfo = model;
    }
    pickInfo: DeliveryInfo;
    public isCloneServiceOption: boolean = false;
    isCloneRevenue: boolean = false;

    daysOfWeek = [
        { value: 0, label: "SUN" },
        { value: 1, label: "MON" },
        { value: 2, label: "TUE" },
        { value: 3, label: "WED" },
        { value: 4, label: "THU" },
        { value: 5, label: "FRI" },
        { value: 6, label: "SAT" },
    ]

    listDays = [];

    ngOnInit() {
        super.ngOnInit();

    }

    onChangeDays(value: string[]): void {
        this.listDays = value;
        this.prepareDataForCloneOrder();
    }

    getLabelDay(dayOfWeeek: number) {
        let item = this.daysOfWeek.find(day => day.value == dayOfWeeek);
        return item.label;
    }

    prepareDataForCloneOrder() {
        let cloneData = [];
        this.cloneWeeklyOrderItemForms.forEach(item => {
            let dataClone = {
                ...item.getDataClone()
            }
            cloneData.push(dataClone);
        });
        return { 
            isCloneRevenue: this.isCloneRevenue,
            isCloneServiceOption: this.isCloneServiceOption,
            cloneDataInfos: cloneData
        };
    }

    getStartDate() {
        let date = DateUtil.getDateOfNextWeek(0);
        return DateUtil.formatDate(date, 'MMM DD, YYYY')
    }

    getEndDate() {
        let date = DateUtil.getDateOfNextWeek(6);
        return DateUtil.formatDate(date, 'MMM DD, YYYY')
    }
}