<div class="flex">
    <div>
        <div class="flex">
            <div class="form-label-v2 fix-height single-line">Including Order Revenue only</div>
            <div nz-checkbox [(ngModel)]="isCloneRevenue" style="margin-left: 5px;"></div>
        </div>
    </div>
    <div style="margin-left: 30px;">
        <div class="flex">
            <div class="form-label-v2 fix-height single-line">Including Service Options only</div>
            <div nz-checkbox [(ngModel)]="isCloneServiceOption" style="margin-left: 5px;"></div>
        </div>
    </div>
</div>
<div class="separator h top20 bottom20"></div>
<div>
    <div class="medium bottom10">Schedule shipments</div>
    <div class="medium bottom10">Select days of week (Pickup time) (Orders will be placed between {{getStartDate()}} and {{getEndDate()}})</div>
    <nz-checkbox-wrapper style="width: 100%; margin-bottom: 10px;" (nzOnChange)="onChangeDays($event)">
        <div nz-row class="flex" style="justify-content: center;">
            <div *ngFor="let day of daysOfWeek" nz-col nzSpan="2">
                <label nz-checkbox [nzValue]="day.value">{{day.label}}</label>
            </div>
        </div>
    </nz-checkbox-wrapper>
    <div *ngIf="listDays.length" nz-row [nzGutter]="{ sm: 16 }" class="top10">
        <div nz-col nzSm="6" class="bold"></div>
        <div nz-col nzSm="6" class="bold">Pickup date <span class="label-mark-required"></span></div>
        <div nz-col nzSm="12" class="bold">Ref No</div>
    </div>
    <div *ngFor="let day of listDays" >
        <div clone-weekly-order-item #cloneWeeklyOrderItemForms [dayOfWeek]="day" [pickInfo]="pickInfo"></div>
    </div>
</div>