<div class="flex">
  <b>Choose Contact</b><span class="label-mark-required right5 left5"></span>
  <div class="cell-spacing" nz-col nzOffset="1">
    <nz-radio-group [(ngModel)]="contactSelected" (ngModelChange)="selectContactSource($event)">
      <!-- <div class="tdm-col" *ngFor="let item of contactSourceOpts">
        <div>
          <label nz-radio [nzValue]="item?.value">{{item?.label}}</label>
        </div>
      </div> -->

      <div nz-radio [nzValue]="contactSources?.shipment?.value" class="radio-source-contact">
        <div
          #shipmentContact
          display-delivery-contact
          [bindData]="shipmentContacts"
          titleHeader="Shipment's contacts"
          [selected]="hasSelectedShipmentContact"
          [disabled]="true"
        ></div>
      </div>

      <div nz-radio [nzValue]="contactSources?.location?.value" class="radio-source-contact">
        <div
          #locationContact
          display-delivery-contact
          [bindData]="locationContacts"
          titleHeader="Location's contacts"
          [selected]="hasSelectedLoactionContact"
          [disabled]="true"
        ></div>
      </div>
    </nz-radio-group>
  </div>

  <!-- <div style="min-width: 500px">
    <nz-select
      nzBackdrop="true"
      nzOptionHeightPx="100"
      style="width: 100%"
      [nzLoading]="isLoading"
      nzPlaceHolder="Select contacts source"
      nzDropdownClassName="choose-source-contact"
      [(ngModel)]="contactSelected"
      (ngModelChange)="selectContactSource($event)"
    >
      <nz-option nzCustomContent *ngFor="let item of contactSourceOpts" [nzValue]="item?.value" [nzLabel]="item?.label">
        <div>{{item?.label}}</div>
        <div class="sub-info">Primary: {{item?.primary}}</div>
        <div class="sub-info">Secondary: {{item?.secondary}}</div>
      </nz-option>
    </nz-select>
  </div> -->
</div>

<!-- <div *ngIf="!showInputContacts" nz-col nzSpan="16" nzOffset="4" class="flex top20">
  <div
    #shipmentContact
    display-delivery-contact
    [bindData]="shipmentContacts"
    titleHeader="Shipment's contacts"
    [selected]="hasSelectedShipmentContact"
  ></div>
  <div
    class="left10"
    #locationContact
    display-delivery-contact
    [bindData]="locationContacts"
    titleHeader="Location's contacts"
    [selected]="hasSelectedLoactionContact"
  ></div>
</div> -->

<div *ngIf="showInputContacts" nz-col nzSpan="16" nzOffset="4" class="top20">
  <div #newContact display-delivery-contact [bindData]="contacts" titleHeader="Create new contacts"></div>
</div>
